import React from "react";
import { BrowserRouter as Router, renderMatches, Route, Routes } from 'react-router-dom';
import  Homepage  from "./pages/homepage";
import About from "./pages/about";
import Navbar from "./components/Navbar/Navbar"
import Rooms from "./pages/rooms"
import ContactBackground from "./components/ContactBackground/ContactBackground";
import Footer from "./components/Footer/Footer";


function App() {
     return (
      <Router>
    <div className="App">
    <Navbar />

      <Routes>
        <Route exact path="/" element={<Homepage />}></Route>
        <Route exact path="/about" element={<About/>}></Route>
        <Route exact path="/rooms" element={<Rooms/>}></Route>
        <Route exact path="/contact" element={<ContactBackground/>}></Route>
        </Routes>
        <Footer />
    </div>


      </Router>
   
  );
  
 
}

export default App;
