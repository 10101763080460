import React, { useState } from 'react'
import Logo from '../../images/logo.png'
import '../Navbar/style.css'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
const Navbar = () => {
  const [click, setClick] = useState(false)
  
  const handleClick = () => setClick(!click)

  return (
    <header>
      <a href="#" className='logo'><img src={Logo} alt="ParadisePoint" /></a>
      <div className={(click) ? "menu-btn active": "menu-btn "} onClick={handleClick}>{(click)  ? <FaTimes  />  :  <FaBars />}</div>
      <div className={(click) ? "navigation active" : "navigation "}>
        <div className="navigation-items">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/rooms">Rooms</Link>
          <Link to="/contact">Contact Us</Link>
          <a className='bookbutton' target={'_blank'} href="https://www.booking.com/hotel/al/paradise-point.html?aid=304142&label=gen173rf-1FCAEoggI46AdIM1gDaAaIAQGYATG4ARjIAQzYAQHoAQH4AQKIAgGiAg5sb2NhbGhvc3Q6MzAwMKgCBLgClZ6XmgbAAgHSAiQ5ZmZhNWVjNi1jMDhjLTQxMTItOWE3Ni05YmQ3NTRhNjFkZTXYAgXgAgE&sid=d1164f564a38bfe6db3a85fed331963f&dist=0;group_adults=3;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=3;req_children=0;room1=A%2CA%2CA;sb_price_type=total;sr_order=distance_from_search;srepoch=1665519397;srpvid=f6678e90700a0151;type=total;ucfs=1&#hotelTmpl">Book Now!</a>
        </div>  
      </div>
      
    </header>
  )
}

export default Navbar