import React from 'react';
import AboutBackground from '../components/AboutBackground/AboutBackground';
import Footer from '../components/Footer/Footer';
function About (){ 
    return (
    <div>
        < AboutBackground />
    </div>
        )
}
export default About;