import React from 'react'
import './style.css'
import moderninterior2 from '../../images/modern-interior2.png'

const Infosection3 = () => {
  return (
    <div className='section3'>
      <div className="info-section3">
            <div className="part1">
                <img className='image-first' src={moderninterior2} alt="" />
            </div>
            <div className="part2">
                <p>Our design would not only impress the guests but also will encourage them to get in a hotel again. These may throw the positive vibe in the hotel ambience, therefore from staff members to customers everyone will be in a state of positivity, happiness and enthusiasm.</p>
            </div>
      </div>
     </div>
  )
}

export default Infosection3