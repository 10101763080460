import React from 'react'
import "./style.css"
import moderninterior1 from '../../images/modern-interior1.jpg'

const LandingPage2 = () => {
  return (
    <div className="all-section">
    <h1 id="readmore"><span>Modern</span> Interior <span>Design</span></h1>
     <div className='section2'>
      
      <div className="info">
          <div className="infopart-1">
            <p >We used trending patterns and playful texture while designing our lobby and rooms. Good choice of colour made it more beautiful and lovely. In context of colours, these are really helpful to change the mood, behaviour, and psychology of a person. </p>
          </div>
          <div className="infopart-2">
            <img className='image2' src={moderninterior1} alt="" />
          </div>
      </div>
     </div>
     </div>
  )
}

export default LandingPage2