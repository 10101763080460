import React from 'react'
import './style.css'
import roominterior1 from '../../images/interior1.jpg'
import roominterior2 from '../../images/interior2.jpg'
import roominterior3 from '../../images/interior-3.jpg'
import roominterior4 from '../../images/interior4.jpg'

const AboutBackground = () => {
  return (
    <div>
        <div className="about-background">
            <h1> About Us </h1>
        </div>
        <div className="about-container">
            <p>Located near the center of Korca, with a very helpful and lovely staff, Paradise Point welcomes everyone who wants to relax. Our hotel is the best option if you are looking for a quiet place to sleep. </p>
        </div>
        <div className="about-container-2">
            <h1>Our Rooms Interior</h1>
            <div className="image-container-2">
                <img className='image-container' src={roominterior1} />
                <img className='image-container' src={roominterior2} />
                <img className='image-container' src={roominterior3} />
            </div>
        </div>
        <div className="about-container-3">
            <h1>Testimonials</h1>
            <div className="testimonials">
            <figure className="snip1157">
  <blockquote>Before booking we had some doubts as there were not many reviews of the'hotel, but once we arrived at the property the owners explained that they had just opened for less than a month. The rooms super clean  and tastefully furnished.
    <div class="arrow"></div>
  </blockquote>
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg" alt="sq-sample3" />
  <div className="author">
    <h5>Feliks </h5>
  </div>
</figure>
<figure className="snip1157 hover">
  <blockquote>New hotel in a very good location. All sights are within easy walking distance.
The hotel is a pure family business. Everyone is very helpful and lovely.
The rooms are very tastefully furnished. The beds are very comfortable.
    <div className="arrow"></div>
  </blockquote>
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample27.jpg" alt="sq-sample27" />
  <div className="author">
    <h5>Dr</h5>
  </div>
</figure>
<figure className="snip1157">
  <blockquote>Central quiet location. Parking in a quiet side street in front of the house. Comfortable beds. Good breakfast with home cooked food.
    <div className="arrow"></div>
  </blockquote>
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample17.jpg" alt="sq-sample17" />
  <div className="author">
    <h5>Sebastian</h5>
  </div>
</figure>
            </div>
        </div>
    </div>
    
  )
}

export default AboutBackground