import React from 'react';
import Footer from '../components/Footer/Footer';
import RoomsBackground from '../components/RoomsBackground/RoomsBackground'

function Rooms (){ 
    return (
    <div>
        <RoomsBackground />
    </div>
        )
}
export default Rooms;