import React from 'react'
import {FaFacebookSquare, FaInstagramSquare, } from 'react-icons/fa'
import {AiFillGoogleSquare} from 'react-icons/ai'
import './style.css'

const LandingPage = () => {
  return (
    <section className="home">
        <div className="container">
        <div className='content'>
            <h1>Wonderful.<br></br><span>Boutique</span></h1>
            <p>Paradise Point is a small-scale boutique hotel with four beautiful cozy stylish rooms.</p>
            <a href="#readmore">Read More</a>
        </div>
        </div>
        <div className="media-icons">
            <a target={"_blank"} href="https://www.facebook.com/boutiqueparadisepoint"> <FaFacebookSquare/></a>
            <a target={"_blank"} href="https://www.instagram.com/boutiqueparadisepoint/"> <FaInstagramSquare/></a>
            <a target={"_blank"} href="https://www.google.com/travel/hotels/Kor%C3%A7%C3%AB/entity/CgsIjPK0tZX4mIKLARAB?q=boutique%20paradise%20point%20korce&g2lb=2502548%2C2503771%2C2503781%2C2504032%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518326%2C4597339%2C4718358%2C4723331%2C4731329%2C4757164%2C4814050%2C4816977%2C4826689%2C4849797%2C4852066%2C4861688%2C4865303%2C4871745%2C4878223%2C4878224%2C26483161&hl=en-AL&gl=al&cs=1&ssta=1&ts=CAESABogCgIaABIaEhQKBwjmDxAKGBESBwjmDxAKGBIYATICEAAqCQoFOgNBTEwaAA&rp=EIzytLWV-JiCiwEQzqD6mbHMmsM1EJyk0P-lscbVRRDHg8vgjfHW6Ak4AkAASAKiAQdLb3LDp8OrwAEDmgICCAA&ap=aAE&ictx=1&ved=0CAAQ5JsGahcKEwi4m_nWod76AhUAAAAAHQAAAAAQBA&utm_campaign=sharing&utm_medium=link&utm_source=htls"> <AiFillGoogleSquare/></a>
        </div>
    </section>
  )
}

export default LandingPage