import tripleroom1 from '../../src/images/tripleroom1.jpg'
import tripleroom2 from '../../src/images/tripleroom2.jpg'
import tripleroom3 from '../../src/images/tripleroom3.jpg'
import tripleroom4 from '../../src/images/tripleroom4.jpg'
import standarddoubleroom1 from '../../src/images/standarddoubleroom1.jpg'
import standarddoubleroom2 from '../../src/images/standarddoubleroom2.jpg'
import standarddoubleroom3 from '../../src/images/standarddoubleroom3.jpg'
import doubleroom1 from '../../src/images/doubleroom1.jpg'
import doubleroom2 from '../../src/images/doubleroom2.jpg'
import doubleroom3 from '../../src/images/doubleroom3.jpg'
import deluxedoubleroom1 from '../../src/images/deluxedoubleroom1.jpg'
import deluxedoubleroom2 from '../../src/images/deluxedoubleroom2.jpg'
import deluxedoubleroom3 from '../../src/images/deluxedoubleroom3.jpg'
import deluxedoubleroom4 from '../../src/images/deluxedoubleroom4.jpg'



export const images = [
    {title: "San Diego", subtitle: "This is San Diego", img: tripleroom1},
    {title: "Salvador Brazil", subtitle: "The best city in the world", img: tripleroom2},
    {title: "UBC Vancouver", subtitle: "The university of British Columbia", img: tripleroom3},
    {title: "fadsfasdfa", subtitle: "SAFDSAFDG", img: tripleroom4},
]

export const images2 = [
    {img: standarddoubleroom2},
    {img: standarddoubleroom3},
    {img: standarddoubleroom1}

]

export const images3 = [
    {img: doubleroom1},
    {img: doubleroom2},
    {img: doubleroom3},
]

export const images4 = [
    {img: deluxedoubleroom1},
    {img: deluxedoubleroom2},
    {img: deluxedoubleroom3},
    {img: deluxedoubleroom4},
]