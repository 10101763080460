import React from 'react'
import './style.css'
import room1 from '../../images/room1.jpg'
import room2 from '../../images/room2.jpg'
import room3 from '../../images/room3.jpg'
import room4 from '../../images/room4.jpg'
import {RiHotelLine} from 'react-icons/ri'
import {MdOutlineDesignServices} from 'react-icons/md'
import {FiWifi} from 'react-icons/fi'
import {BiFoodMenu} from 'react-icons/bi'
import {FaParking} from 'react-icons/fa'

const Infosection4 = () => {
  return (
    
    <div className='section4'>
      <div className="title2">
      <h1> We offer a wide selection of  <br></br> <span>services</span></h1>
      </div>
          <div className="content3">
            <div className="card1">
              <div className="icon">
                  <div className="material-icons"><MdOutlineDesignServices/></div>
              </div>
              <p className='title'>Design</p>
              <p className="text">Upscale interior with home-like feel</p>
            </div>
            <div className="card1">
            <div className="icon">
                <div className="material-icons"><FiWifi/></div>
              </div>
              <p className='title'>Wi-fi</p>
              <p className="text">Free wi-fi for our clients</p>
            </div>
            <div className="card1">
            <div className="icon">
                <div className="material-icons"><BiFoodMenu/></div>
              </div>
              <p className='title'>Breakfast</p>
              <p className="text">We offer a continental or buffet breakfast</p>
            </div>
            <div className="card1">
            <div className="icon">
                <div className="material-icons"><FaParking/></div>
              </div>
              <p className='title'>Parking</p>
              <p className="text">Free public parking is available nearby</p>
            </div>
        </div>
        <div className="title2">
        <h1> Explore Our <span>Boutique</span> </h1>
        </div>
        <div className="hero-section">
  <div className="card-grid">
    <a className="card" href="/rooms">
      <div className="card__background" style={{backgroundImage: `url(${room1})`}}></div>
      <div className="card__content">
        <p className="card__category">Our Rooms</p>
        <h3 className="card__heading">Triple Room</h3>
      </div>
    </a>
    <a className="card" href="/rooms">
      <div className="card__background" style={{backgroundImage: `url(${room2})`}}></div>
      <div className="card__content">
        <p className="card__category">Our Rooms</p>
        <h3 className="card__heading">Standard Double Room</h3>
      </div>
    </a>
    <a className="card" href="/rooms">
      <div className="card__background" style={{backgroundImage: `url(${room3})`}}></div>
      <div className="card__content">
        <p className="card__category">Our Rooms</p>
        <h3 className="card__heading">Double Room </h3>
      </div>
    </a>
    <a className="card" href="/rooms">
      <div className="card__background" style={{backgroundImage: `url(${room4})`}}></div>
      <div className="card__content">
        <p className="card__category">Our Rooms</p>
        <h3 className="card__heading">Deluxe Double Room</h3>
      </div>
    </a>
  <div>
</div>
</div>
</div>
    </div>
  )
}

export default Infosection4