import React from 'react';
import LandingPage from "../components/LandingPage/LandingPage";
import LandingPage2 from "../components/LandingPage2/LandingPage2"
import Infosection3 from "../components/Infosection3/Infosection3";
import Infosection4 from "../components/Infosection4/Infosection4";
import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"

function Homepage  () {
    return (
        <div>
                <LandingPage />
                <LandingPage2 />
                <Infosection3 />
                <Infosection4 />
        </div>
    )
}
export default Homepage;